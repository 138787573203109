<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" @click="openModalAgregar()" v-if="$agregar(['Usuarios'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus', }" class="icon btn-icon" /> NUEVO USUARIO
          </a>
        </transition>
      </div>
    </div>
    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-12">
          <div class="table-responsive site-table2-responsive">
            <div class="row">
              <div class="col-lg-12 text-right">
                <b-button class="btn-detail m-2"
                  @click="!loading ? updateTable() : undefined"
                  :disabled="loading"
                  v-b-popover.hover="'Actualizar listado'">
                  <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
                  <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'sync-alt', }" class="icon btn-icon"/>
                </b-button>
              </div>
            </div>
            <!-- Tabla de datos -->
            <ag-grid-vue
                class="ag-grid-table ag-theme-balham"
                style='height:65vh'
                :localeText="locale"
                :animateRows="true"
                rowSelection="single"

                :gridOptions="gridOptions"
                :context="context"
                :suppressDragLeaveHidesColumns="true"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :statusBar="statusBar"
                :overlayNoRowsTemplate="$overlayNoRowsTemplate()"
                :frameworkComponents="frameworkComponents"

                :rowModelType="rowModelType"
                :cacheBlockSize="paginationPageSize"

                @grid-ready="onGridReady"
                @gridSizeChanged="gridSizeChanged"
                @row-selected="rowSelected"
                @row-double-clicked="rowDblClick"
                @column-resized="saveColumnState"
                @column-moved="saveColumnState"
                @sort-changed="saveColumnState"
                @first-data-rendered="gridFirstDataRendered">
            </ag-grid-vue>
          </div>
        </div>
      </transition>
    </div>
    <b-modal
      id="bv-modal-agregar" size="md" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$agregar(['Usuarios'])">
      <template #modal-title>
        AGREGAR USUARIO
      </template>
      <form class="form-site inner" @submit.prevent="submit()">
        <div class="form-row form-row-select">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('nombre')}">
            <fieldset>
              <legend>Nombre o Razón Social</legend>
              <input type="text" class="form-control" placeholder="Nombre de Usuario" v-model="nombre">
              <span class="icon" :title="validation.firstError('nombre')" v-if="validation.hasError('nombre')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('email')}">
            <fieldset>
              <legend>E-mail (Usuario para sesión)</legend>
              <input type="text" class="form-control" placeholder="E-mail" autocomplete="off" v-model="email">
<!--              <span class="icon" :title="validation.firstError('email')" v-if="validation.hasError('email')">-->
<!--                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>-->
<!--              </span>-->
            </fieldset>
            <div class="error-msg mt-1 mb-0" v-if="validation.hasError('email')">** {{ validation.firstError('email') }}</div>
          </div>
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('password')}">
            <fieldset>
              <legend>Contraseña</legend>
              <div class="input-group">
                <input v-if="showPassword" type="text" class="form-control" placeholder="Contraseña" autocomplete="off" v-model="password">
                <input v-else type="password" class="form-control" placeholder="Contraseña" autocomplete="off" v-model="password">

                <div class="input-group-append cursor-pointer">
                    <span class="input-group-text">
                      <div class="toggle-show-btn" @click="toggleShow">
                        <font-awesome-icon :icon="{ prefix: 'fa', iconName: showPassword ? 'eye-slash' : 'eye' }" class="no-help"/>
                      </div>
                    </span>
                </div>
              </div>
            </fieldset>
            <div class="error-msg mt-1 mb-0" v-if="validation.hasError('password')">** {{ validation.firstError('password') }}</div>
          </div>
          <div class="form-holder form-holder-2">
          <fieldset :class="validation.hasError('perfilId') ? 'select-error' : ''">
              <legend>Perfil de usuario</legend>
              <v-select
                class="form-select"
                placeholder="Perfil"
                label="Nombre"
                :options="perfilesUsu"
                :reduce="perfil => perfil.PerfilId"
                :searchable="false"
                :clearable="false"
                v-model="perfilId"></v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('empresaIds') ? 'select-error' : ''">
              <legend>Empresas Permitidas</legend>
              <v-select
                class="form-select"
                placeholder="Elegir empresas"
                label="Nombre"
                @option:selecting="empresaSelected"
                :options="empresas"
                :reduce="empresa => empresa.EmpresaId"
                :selectable="(option) => !(empresaIds.includes(option.EmpresaId)) && !(empresaIds.includes(0))"
                :searchable="true"
                :clearable="false"
                taggable
                multiple
                push-tags
                v-model="empresaIds"></v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('provIds') ? 'select-error' : ''">
              <legend>Proveedores Permitidos</legend>
              <v-select
                class="form-select"
                placeholder="Elegir proveedores"
                label="RazonSocial"
                @option:selecting="proveedorSelected"
                :options="proveedores"
                :reduce="proveedor => proveedor.ProveedorId"
                :selectable="(option) => !(provIds.includes(option.ProveedorId)) && !(provIds.includes(-1))"
                :searchable="true"
                :clearable="false"
                taggable
                multiple
                push-tags
                v-model="provIds"></v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('cfdisPropios') ? 'select-error' : ''">
              <legend>Visualización de CFDIs</legend>
              <b-form-checkbox
                v-model="cfdisPropios"
              >
              ¿Solo ve sus CFDis cargados?
              </b-form-checkbox>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>
    <b-modal
      id="bv-modal-editar" size="md" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$editar(['Usuarios'])">
      <template #modal-title>
        EDITAR USUARIO
      </template>
      <form class="form-site inner" @submit.prevent="submit()">
        <div class="form-row form-row-select">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('nombre')}">
            <fieldset>
              <legend>Nombre o Razón Social</legend>
              <input type="text" class="form-control" placeholder="Nombre de Usuario" v-model="nombre">
              <span class="icon" :title="validation.firstError('nombre')" v-if="validation.hasError('nombre')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('email')}">
            <fieldset>
              <legend>E-mail (Usuario para sesión)</legend>
              <input type="text" class="form-control" placeholder="E-mail" autocomplete="off" v-model="email">
              <span class="icon" :title="validation.firstError('email')" v-if="validation.hasError('email')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('perfilId') ? 'select-error' : ''">
              <legend>Perfil de usuario</legend>
              <v-select
                class="form-select"
                placeholder="Perfil"
                label="Nombre"
                :options="esprov === 1 ? perfilesProv : perfilesUsu"
                :reduce="perfil => perfil.PerfilId"
                :searchable="false"
                :clearable="false"
                v-model="perfilId"></v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('esrepse') ? 'select-error' : ''">
              <legend>¿Es REPSE?</legend>
              <v-select
                class="form-select"
                placeholder="¿Es proveedor REPSE?"
                label="label"
                :options="[{ label: 'SI', value: 1 }, { label: 'NO', value: 0 }]"
                :reduce="(option) => option.value"
                :searchable="false"
                :clearable="false"
                v-model="esrepse">
                <template v-slot:option="option">
                    <b-badge class="font-size-14" :variant="option.value === 1 ? 'success' : 'secondary'">{{ option.label }}</b-badge>
                </template>
                <template #selected-option="{ label, value }">
                  <b-badge class="font-size-14" :variant="value === 1 ? 'success' : 'secondary'">{{ label }}</b-badge>
                </template>
              </v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('esinterno') ? 'select-error' : ''">
              <legend>¿Es Proveedor Interno?</legend>
              <v-select
                class="form-select"
                placeholder="¿Es proveedor Interno?"
                label="label"
                :options="[{ label: 'SI', value: 1 }, { label: 'NO', value: 0 }]"
                :reduce="(option) => option.value"
                :searchable="false"
                :clearable="false"
                v-model="esinterno">
                <template v-slot:option="option">
                    <b-badge class="font-size-14" :variant="option.value === 1 ? 'success' : 'secondary'">{{ option.label }}</b-badge>
                </template>
                <template #selected-option="{ label, value }">
                  <b-badge class="font-size-14" :variant="value === 1 ? 'success' : 'secondary'">{{ label }}</b-badge>
                </template>
              </v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('esactivo') ? 'select-error' : ''">
              <legend>Estatus</legend>
              <v-select
                class="form-select"
                placeholder="Es Activo"
                label="label"
                :options="[{ label: 'Activo', value: 1 }, { label: 'Inactivo', value: 0 }]"
                :reduce="(option) => option.value"
                :searchable="false"
                :clearable="false"
                v-model="esactivo">
                <template v-slot:option="option">
                    <b-badge class="font-size-14" :variant="option.value === 1 ? 'success' : 'secondary'">{{ option.label }}</b-badge>
                </template>
                <template #selected-option="{ label, value }">
                  <b-badge class="font-size-14" :variant="value === 1 ? 'success' : 'secondary'">{{ label }}</b-badge>
                </template>
              </v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('empresaIds') ? 'select-error' : ''">
              <legend>Empresas Permitidas</legend>
              <v-select
                class="form-select"
                placeholder="Elegir empresas"
                label="Nombre"
                @option:selecting="proveedorSelected"
                :options="empresas"
                :reduce="empresa => empresa.EmpresaId"
                :selectable="(option) => !(empresaIds.includes(option.EmpresaId)) && !(empresaIds.includes(0))"
                :searchable="true"
                :clearable="false"
                taggable
                multiple
                push-tags
                v-model="empresaIds"></v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('provIds') ? 'select-error' : ''">
              <legend>Proveedores Permitidos</legend>
              <v-select
                class="form-select"
                placeholder="Elegir proveedores"
                label="RazonSocial"
                @option:selecting="proveedorSelected"
                :options="proveedores"
                :reduce="proveedor => proveedor.ProveedorId"
                :selectable="(option) => !(provIds.includes(option.ProveedorId)) && !(provIds.includes(-1))"
                :searchable="true"
                :clearable="false"
                taggable
                multiple
                push-tags
                v-model="provIds"></v-select>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('cfdisPropios') ? 'select-error' : ''">
              <legend>Visualización de CFDIs</legend>
              <b-form-checkbox
                v-model="cfdisPropios"
              >
              ¿Solo ve sus CFDis cargados?
              </b-form-checkbox>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import AgCellActions from '@/components/UsuariosTable/AgCellActions.vue';
import AgCellEstatusBadge from '@/components/UsuariosTable/AgCellEstatusBadge.vue';
import AgFilter from '@/components/UsuariosTable/AgFilter.vue';
import AgStatusBar from '@/components/AgStatusBar.vue';
import { mapState } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      loading: false,
      empresas: {},
      proveedores: {},
      perfiles: {},
      perfilesProv: {},
      perfilesUsu: {},
      perfilId: '',
      action: '',
      userId: 0,
      nombre: '',
      email: '',
      password: '',
      showPassword: false,
      esrepse: 0,
      esinterno: 0,
      esactivo: 1,
      empresaIds: [0],
      provIds: [-1],
      cfdisPropios: false,
      esprov: 0,
      /** Configuracion de tabla ag-grid */
      // eslint-disable-next-line no-undef
      locale: AG_GRID_LOCALE_ES,
      context: null,
      gridOptions: {
        nameTable: 'agUsuarios',
        suppressMenuHide: true,
      },
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        sortable: true,
        filter: true,
        unSortIcon: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'statusBarComponent', align: 'left' },
          { statusPanel: 'agSelectedRowCountComponent' },
        ],
      },
      frameworkComponents: {
        actionsTemplate: AgCellActions,
        estatusTemplate: AgCellEstatusBadge,
        checkFilterTemplate: AgFilter,
        statusBarComponent: AgStatusBar,
      },
      selectedRow: {},
      selectedRowKeys: [],
      columnDefs: [
        {
          headerName: '#',
          field: 'UserId',
          filter: false,
          pinned: 'left',
          width: '50px',
          resizable: false,
          cellClass: 'text-center line-numbers',
          cellRenderer: 'loadingRenderer',
        },
        {
          headerName: 'Nombre',
          field: 'Nombre',
          filter: 'agTextColumnFilter',
          width: '280px',
        },
        {
          headerName: 'Email',
          field: 'Email',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Perfil',
          field: 'Perfil',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: '¿Es Activo?',
          field: 'EsActivo',
          cellRendererSelector: () => ({
            component: 'estatusTemplate',
          }),
          cellClass: 'text-center',
          width: '120px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
        },
        {
          headerName: '¿Es Proveedor?',
          field: 'EsProveedor',
          cellRenderer: (params) => (params.value === 1 ? 'SI' : 'NO'),
          cellClass: 'text-center',
          width: '95px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
        },
        {
          headerName: '¿Es Interno?',
          field: 'EsInterno',
          cellRenderer: (params) => (params.value === 1 ? 'SI' : 'NO'),
          cellClass: 'text-center',
          width: '95px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
        },
        {
          headerName: '¿Es REPSE?',
          field: 'EsRepse',
          cellRenderer: (params) => (params.value === 1 ? 'SI' : 'NO'),
          cellClass: 'text-center',
          width: '95px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
        },
        {
          headerName: 'Intentos Sesión',
          field: 'LoginAttemps',
          filter: 'agTextColumnFilter',
          cellClass: 'text-center',
          width: '95px',
        },
        {
          headerName: 'Acciones',
          filter: false,
          floatingFilter: false,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          width: '110px',
          pinned: window.innerWidth < 1750 ? 'right' : false,
          cellClass: 'text-center',
          cellRendererSelector: () => ({
            component: 'actionsTemplate',
          }),
        },
      ],
      rowModelType: 'serverSide',
      paginationPageSize: 30,
    };
  },
  validators: {
    nombre(value) { return Validator.value(value).required('Campo obligatorio').lengthBetween(5, 250, 'Longitud de 5 a 250 caracteres'); },
    email(value) { return Validator.value(value).required('Campo requerido').email('Formato de correo no válido').lengthBetween(5, 100, 'Longitud debe ser entre 5 y 6 caracteres'); },
    password(value) { return Validator.value(value).required('Campo requerido').lengthBetween(6, 100, 'Longitud minima de 6 caracteres y al menos 1 número, 1 letra y 1 símbolo.'); },
    perfilId(value) { return Validator.value(value).required('Campo requerido').digit().lengthBetween(1, 100, 'Longitud minima de 1'); },
    esactivo(value) { return Validator.value(value).required('Campo requerido').between(0, 1, 'Valor incorrecto'); },
    esrepse(value) { return Validator.value(value).required('Campo requerido').between(0, 1, 'Valor incorrecto'); },
    esinterno(value) { return Validator.value(value).required('Campo requerido').between(0, 1, 'Valor incorrecto'); },
    empresaIds(value) { return Validator.value(value).required('Campo requerido'); },
    provIds(value) { return Validator.value(value).required('Campo requerido'); },
    cfdisPropios(value) { return Validator.value(value).required('Campo requerido'); },
  },
  computed: {
    ...mapState(['aggrid']),
  },
  mounted() {
    this.context = { componentParent: this };
    this.getPerfiles();
    this.getEmpresas();
    this.getProveedores();
  },
  methods: {
    // Evento cuando la tabla se inicializa
    onGridReady(params) {
      // Creamos variable global de params (propiedates y funciones de agGrid)
      this.agGrid = params;

      // Llamada a la funcion que actualiza la tabla
      this.getData();
      this.gridSizeChanged(this.agGrid);
    },
    // Metodo para ajustar tamaño de tabla cuando las columnas no cubren por completo el grid
    gridSizeChanged(params) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        if (!column.colDef.autoSize) { allColumnIds.push(column.colId); }
      });

      // params.columnApi.autoSizeColumns(allColumnIds);
    },
    saveColumnState() {
      const columnState = this.agGrid.columnApi?.getColumnState();
      this.$store.commit('SAVE_AGGRIDSTATE', { tblname: `${this.gridOptions?.nameTable}State`, columnstate: columnState });
    },
    gridFirstDataRendered() {
      const columnState = this.aggrid[`${this.gridOptions?.nameTable}State`];
      if (columnState) {
        this.agGrid.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },
    // Metodo para actualizar filas seleccionadas
    rowSelected(params) {
      // eslint-disable-next-line prefer-destructuring
      const selectedRow = params.api.getSelectedNodes()[0];
      this.selectedRowKeys = selectedRow.data.UserId;
      this.selectedRow = selectedRow?.data;
    },
    // Metodo para abrir modal al dar doble click en una fila
    rowDblClick(params) {
      if (this.$permisos_or([['Usuarios', 'Ver'], ['Usuarios', 'Editar']])) {
        this.openModalEditar(params.data);
      }
    },
    // Metodo para limpiar todos los filtros
    clearFilters() {
      if (Object.entries(this.filters.filterModel).length) this.loading = true;
      this.agGrid.api.setFilterModel(null);
    },
    // Metodo para actualiza datos de la tabla incluyendo filtros
    updateTable() {
      this.getData();
    },
    // Metodo para actualizar datos de la tabla (server - side)
    getData() {
      this.agGrid.api.setServerSideDatasource(
        this.$createDataSource(
          this,
          apiClient,
          'users', // ruta
        ),
      );
    },
    getPerfiles() {
      apiClient.get('/perfiles')
        .then((res) => {
          this.perfiles = res.data;
          this.perfilesProv = this.perfiles.filter((perfil) => perfil.Tipo === 1);
          this.perfilesUsu = this.perfiles.filter((perfil) => perfil.Tipo === 0);
        })
        .catch(() => {});
    },
    getEmpresas() {
      apiClient.get('/empresas', {
        params: {
          viewall: 1,
        },
      })
        .then((res) => {
          // res.data.unshift({ EmpresaId: 0, Nombre: '(TODAS)', Alias: '(TODAS)' });
          this.empresas = res.data;
        })
        .catch(() => {});
    },
    getProveedores() {
      apiClient.get('/proveedores-list')
        .then((res) => {
          this.proveedores = res.data;
        })
        .catch(() => {});
    },
    openModalAgregar() {
      this.nombre = '';
      this.email = '';
      this.password = '';
      this.perfilId = '';
      this.esactivo = 1;
      this.empresaIds = [0];
      this.provIds = [-1];
      this.cfdisPropios = false;
      this.action = 'add';
      this.$bvModal.show('bv-modal-agregar');
    },
    openModalEditar(row) {
      this.userId = row.UserId;
      this.nombre = row.Nombre;
      this.email = row.Email;
      this.password = '*******'; // Simulacion de validación, ya que no se requiere para el update
      this.perfilId = row.PerfilId;
      this.esrepse = row.EsRepse;
      this.esactivo = row.EsActivo;
      this.esprov = row.EsProveedor;
      this.esinterno = row.EsInterno;
      this.cfdisPropios = row?.CfdisPropios === 1;
      this.empresaIds = row?.EmpresaIds?.split(',') || [0];
      this.empresaIds = this.empresaIds.map((str) => parseInt(str, 10));
      this.provIds = row?.ProvIds?.split(',') || [-1];
      this.provIds = this.provIds.map((str) => parseInt(str, 10));
      this.action = 'edit';
      this.$bvModal.show('bv-modal-editar');
    },
    openModalEliminar(row) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará el Usuario seleccionado.', {
        title: 'Eliminar Usuario',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) {
            this.userId = row.UserId;
            this.action = 'delete';
            this.submit();
          }
        })
        .catch(() => {});
    },
    submit() {
      if (this.action === 'add' || this.action === 'edit') {
        this.$validate().then((success) => {
          if (success) {
            this.loading = true;
            if (this.action === 'add') {
              apiClient.post('/users', {
                nombre: this.nombre,
                email: this.email,
                password: this.password,
                perfilId: this.perfilId,
                empresaIds: this.empresaIds,
                provIds: this.provIds,
                cfdisPropios: this.cfdisPropios,
              })
                .then(() => {
                  this.updateTable();
                  this.$bvModal.hide('bv-modal-agregar');
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
            if (this.action === 'edit') {
              apiClient.put('/users', {
                IdUser: this.userId,
                nombre: this.nombre,
                email: this.email,
                perfilId: this.perfilId,
                esrepse: this.esrepse,
                esinterno: this.esinterno,
                esactivo: this.esactivo,
                empresaIds: this.empresaIds,
                provIds: this.provIds,
                cfdisPropios: this.cfdisPropios,
              })
                .then(() => {
                  this.updateTable();
                  this.$bvModal.hide('bv-modal-editar');
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          }
        });
      }
      if (this.action === 'delete') {
        apiClient.delete('/users', {
          data: {
            IdUser: this.userId,
          },
        })
          .then(() => {
            this.updateTable();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    empresaSelected(val) {
      this.$nextTick(() => {
        if (val.EmpresaId === 0) this.empresaIds = [0];
      });
    },
    proveedorSelected(val) {
      this.$nextTick(() => {
        if (val.ProveedorId === -1) this.provIds = [-1];
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {
    email() {
      this.$nextTick(() => {
        this.email = this.email.replace(/\s+/g, '');
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
